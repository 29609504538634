import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { createFetcher } from '../../contexts/Fetcher';
import { Link, useHistory } from 'react-router-dom';
import { useUsersApi } from '../../hooks/useUsersApi';
import { DateFormat } from '../../helpers/dateFormat';
import { CustomerRoutes } from '../../routers/CustomerRoutes';
import { EditUserDialog } from '../EditUserDialog/EditUserDialog';
import { AssignLicensesDialog } from '../AssignLicenses/AssignLicensesDialog';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { ConditionalTooltip } from '../ConditionalTooltip/ConditionalTooltip';
import { PageHeader } from '../PageHeader/PageHeader';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { useIntParams } from '../../hooks/useIntParams';
import { UserLicenses } from './UserLicenses';
import { DangerousButton } from '../CustomButtons/DangerousButton';
import { useQueryParam } from '../../hooks/useQueryParam';
import { useQueryUrl } from '../../hooks/useQueryUrl';
import styled from '@mui/styles/styled';
import Refresh from '@mui/icons-material/Refresh';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useTranslation } from 'react-i18next';
import { Date } from '../Date/Date';
import { RequestOfflineLicenseStandalone } from '../RequestOfflineLicenseStandalone/RequestOfflineLicenseStandalone';
import { IAdminCustomerUserResponse } from '../../models/AdminCustomerUserResponse';
import { newRequestFileExtension } from '../RequestOfflineLicenseStandalone/offlineRequestConst';
import { routeWithQuery } from '../../helpers/routeWithQuery';
import { IAssignedPackage } from '../../models/CustomerLicense';

interface IUrlParams {
  userId: string;
  productId?: string;
  getOfflineLicense?: string;
  releaseOfflineLicense?: string;
  unassignLicense?: string;
}

export const UserFetcher = createFetcher(() => {
  const { userId } = useIntParams<IUrlParams>();
  const { getCustomerUser } = useUsersApi();
  return useCallback(() => {
    return getCustomerUser([userId]);
  }, [userId, getCustomerUser]);
});

const ProfileLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

const ProfileValue = styled(Typography)({
  overflowWrap: 'break-word',
});

const UserContent: FC = () => {
  const { data, onRefresh } = useContext(UserFetcher.Context);
  const history = useHistory();
  const { deleteUser, reInviteUser, requestOfflineLicenseWithAddons, offlineActivate, getOfflineRequestVersion } =
    useUsersApi();
  const apiErrorHandler = useApiErrorHandler();
  const { userId, productId } = useIntParams<IUrlParams>();
  const action = useQueryParam('action');
  const isEditOpen = action === 'edit';
  const isDeleteOpen = action === 'delete';
  const isAssignLicensesOpen = action === 'licenses';
  const isRequestOfflineOpen = action === 'offline';
  const { addNotification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const editUrl = useQueryUrl('action', 'edit');
  const deleteUrl = useQueryUrl('action', 'delete');
  const assignLicensesUrl = useQueryUrl('action', 'licenses');
  const requestOfflineUrl = useQueryUrl('action', 'offline');
  const noActionUrl = useQueryUrl('action', null);

  const closeDialog = useCallback(() => {
    history.push(noActionUrl);
  }, [noActionUrl]);

  const onDeleteUser = useCallback(async () => {
    if (!data) return;
    try {
      await deleteUser([data.user.id]);
      history.push(CustomerRoutes.Users());
    } catch (e) {
      apiErrorHandler(e);
    }
  }, [data?.user.id, deleteUser]);

  const onResend = useCallback(async () => {
    if (!data) return;
    try {
      await reInviteUser([data.user.id], data.user.id);
      addNotification({
        message: t('user.invitationResent'),
        severity: 'success',
      });
    } catch (e) {
      apiErrorHandler(e);
    }
  }, [data?.user.id, reInviteUser]);

  const onRequestOffline = useCallback(
    async (file: File, basePackages: number[], addonPackages: number[]) => {
      if (file.name.endsWith(newRequestFileExtension)) {
        await requestOfflineLicenseWithAddons([userId], file, basePackages, addonPackages);
      } else {
        await offlineActivate([userId, basePackages[0]], file);
      }
      await onRefresh();
      history.push(
        routeWithQuery(
          CustomerRoutes.UserLicenseOffline,
          [userId, productId, basePackages[0]],
          { action: '' },
          history.location.search
        )
      );
    },
    [userId, productId, requestOfflineLicenseWithAddons, offlineActivate, onRefresh]
  );

  const onTabChange = useCallback((_e, tab: number) => {
    history.push(CustomerRoutes.UserProduct(userId, tab));
  }, []);

  useEffect(() => {
    if (!productId && data && data.assignedLicenses.length > 0) {
      history.replace(CustomerRoutes.UserProduct(userId, data.assignedLicenses[0].product.id));
    }
  }, [productId, data]);

  const packages = data?.assignedLicenses.find((assignedLicenses) => {
    return assignedLicenses.product.id === productId;
  })?.packages;

  const allPackages = data?.assignedLicenses.reduce((out, assignedLicense) => {
    return [...out, ...assignedLicense.packages];
  }, [] as IAssignedPackage[]);

  const packagesForOffline = useMemo<IAdminCustomerUserResponse['machinePackages']>(() => {
    if (!allPackages) return [];
    return allPackages.map((packageData) => {
      return {
        package: {
          package: packageData.licenses.package,
          userPackage: packageData.userPackage,
          plan: packageData.plan,
        },
        plan: packageData.plan,
        license: packageData.licenses.license,
        machine: packageData.licenses.machine,
        offlineLicense: packageData.licenses.offlineLicense,
      };
    });
  }, [allPackages]);

  const getPixotopeVersion = useCallback(
    (file: File) => getOfflineRequestVersion([userId], file),
    [getOfflineRequestVersion, userId]
  );

  if (!data) return null;

  return (
    <div>
      <PageHeader
        title={`${data.user.firstName} ${data.user.lastName}`}
        crumbs={[{ title: t('users.header'), url: CustomerRoutes.Users() }]}
      >
        {data.user.verified ? null : (
          <Button variant="contained" color="secondary" endIcon={<Refresh />} onClick={onResend}>
            {t('user.resentInvitationButton')}
          </Button>
        )}
        <ConditionalTooltip enabled={data.assignedLicenses.length > 0} title={t('user.cannotDeleteTooltip') as string}>
          <span>
            <DangerousButton component={Link} to={deleteUrl} disabled={data.assignedLicenses.length > 0}>
              {t('common.button.delete')}
            </DangerousButton>
          </span>
        </ConditionalTooltip>

        <Button component={Link} to={editUrl} variant="contained" color="primary">
          {t('common.button.edit')}
        </Button>
      </PageHeader>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <ProfileLabel variant="caption">{t('common.tableHeader.name')}</ProfileLabel>
          <ProfileValue variant="body1">
            {data.user.firstName} {data.user.lastName}
          </ProfileValue>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <ProfileLabel variant="caption">{t('common.tableHeader.email')}</ProfileLabel>
          <ProfileValue variant="body1">{data.user.email}</ProfileValue>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <ProfileLabel variant="caption">{t('common.tableHeader.phone')}</ProfileLabel>
          <ProfileValue variant="body1">{data.user.phoneNumber ?? '-'}</ProfileValue>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <ProfileLabel variant="caption">{t('user.lastUpdateLabel')}</ProfileLabel>
          <ProfileValue variant="body1">
            <Date date={data.user.updated} format={DateFormat.DateWithFullTime} />
          </ProfileValue>
        </Grid>
      </Grid>

      <Box mt={3} mb={3}>
        <Divider />
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Tabs
            value={productId}
            indicatorColor="primary"
            textColor="primary"
            onChange={onTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {data.assignedLicenses.map((license) => {
              return <Tab label={license.product.name} value={license.product.id} key={license.product.id} />;
            })}
          </Tabs>
        </Grid>
        <Grid item xs={4} justifyContent="right" display="flex" gap={1} alignItems="center">
          <Button component={Link} to={assignLicensesUrl} variant="contained">
            {t('user.assignLicensesButton')}
          </Button>

          <Button component={Link} to={requestOfflineUrl} variant="outlined" color="primary">
            {t('user.requestOfflineButton')}
          </Button>
        </Grid>
      </Grid>

      {packages && allPackages ? <UserLicenses packages={packages} allPackages={allPackages} /> : null}

      <AssignLicensesDialog open={isAssignLicensesOpen} closeDialog={closeDialog} />
      <RequestOfflineLicenseStandalone
        open={isRequestOfflineOpen}
        onClose={closeDialog}
        machinePackages={packagesForOffline}
        onSubmit={onRequestOffline}
        getPixotopeVersion={getPixotopeVersion}
      />
      <EditUserDialog open={isEditOpen} closeDialog={closeDialog} />
      <ConfirmationDialog
        open={isDeleteOpen}
        title={t('user.deleteDialog.title')}
        message={t('user.deleteDialog.message', data.user)}
        confirm={onDeleteUser}
        abort={closeDialog}
        dangerous
        confirmLabel={t('common.button.delete')}
      />
    </div>
  );
};

export const User: FC = () => {
  return (
    <UserFetcher.WAF>
      <UserContent />
    </UserFetcher.WAF>
  );
};
