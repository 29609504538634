import { useApi } from './useApi';
import { useCallback } from 'react';
import { IUserPackageDetails } from '../models/UserPackageDetails';
import { IPlan } from '../models/Plan';
import { IMachine } from '../models/Machine';
import { ILicenseDetails } from '../models/LicenseDetails';
import { IPackageDetails } from '../models/PackageDetails';
import { IAirgapRequestVersionResponse } from '../types/AirgapRequestVersion';

const LicenseEndpoints = {
  CustomerLicenses: () => '/licenses',
  CustomerLicense: (userPackageId: number) => `/licenses/packages/${userPackageId}/license`,
  CustomerOfflineLicense: (userPackageId: number) => `/licenses/packages/${userPackageId}/offline`,
  CustomerOfflineLicenseWithAddons: () => `/licenses/packages/offline`,
  CustomerOfflineRequestVersion: () => `/licenses/packages/get-offlic-req-version`,
};

export interface IUserPackage {
  license: ILicenseDetails;
  machine?: IMachine;
  offlineLicense?: string;
  package: {
    package: IPackageDetails;
    userPackage: IUserPackageDetails;
    plan: IPlan;
  };
}

export interface IUserLicensesResponse {
  step: {
    step?: number;
    userPackageId?: number;
  };
  userPackages: IUserPackage[];
}

export const useLicenseApi = () => {
  const { del, get, post } = useApi();

  const getLicenses = useCallback(async () => {
    const result = await get<IUserLicensesResponse>(LicenseEndpoints.CustomerLicenses());
    return result.data;
  }, [get]);

  const offlineRequest = useCallback(
    async (userPackageId: number, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      const result = await post<IUserLicensesResponse>(
        LicenseEndpoints.CustomerOfflineLicense(userPackageId),
        formData
      );
      return result.data;
    },
    [post]
  );

  const offlineRequestWithAddons = useCallback(
    async (file: File, basePackages: number[], addonPackages: number[]) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              basePackages,
              addonPackages,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );
      const result = await post<IUserLicensesResponse>(LicenseEndpoints.CustomerOfflineLicenseWithAddons(), formData);
      return result.data;
    },
    [post]
  );

  const offlineRelease = useCallback(
    async (userPackageId: number, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      const result = await del<void>(LicenseEndpoints.CustomerOfflineLicense(userPackageId), { data: formData });
      return result.data;
    },
    [del]
  );

  const offlineReleaseWithAddons = useCallback(
    async (file: File, basePackages: number[], addonPackages: number[]) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              basePackages,
              addonPackages,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );
      const result = await del<IUserLicensesResponse>(LicenseEndpoints.CustomerOfflineLicenseWithAddons(), {
        data: formData,
      });
      return result.data;
    },
    [del]
  );

  const offlineRequestVersion = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      const result = await post<IAirgapRequestVersionResponse>(
        LicenseEndpoints.CustomerOfflineRequestVersion(),
        formData
      );
      return result.data;
    },
    [del]
  );

  const onlineRelease = useCallback(
    async (userPackageId: number) => {
      const result = await del<void>(LicenseEndpoints.CustomerLicense(userPackageId));
      return result.data;
    },
    [del]
  );

  return {
    getLicenses,
    offlineRequest,
    offlineRequestWithAddons,
    offlineRelease,
    offlineReleaseWithAddons,
    onlineRelease,
    offlineRequestVersion,
  } as const;
};
