import React, { FC, useCallback, useContext, useMemo } from 'react';
import { UrlQueryDataGrid } from '../UrlQueryDataGrid/UrlQueryDataGrid';
import { GridColumns, GridRowData } from '@mui/x-data-grid';
import { CustomerUserFetcher, CustomerUserPackagesFetcher } from './CustomerUserFetchers';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import { getLicenseMode, LicenseMode } from '../../helpers/licenseMode';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { AdminRoutes } from '../../routers/AdminRoutes';
import { ICustomerUserUrlParams } from './CustomerUser';
import { getEnumIsFilterOperator, getEnumIsNotFilterOperator } from '../../filterOperators/enumFilterOperator';
import { getDateAfterFilterOperator, getDateBeforeFilterOperator } from '../../filterOperators/dateFilterOperators';
import { CustomerUserAssignLicensesDialog } from './CustomerUserAssignLicenseDialog';
import { useIsRoute } from '../../hooks/useIsRoute';
import { LicenseContext } from '../../contexts/LicenseContext';
import { OfflineAccessStepsDialog } from '../Licenses/OfflineAccessStepsDialog';
import { ReleaseLicenseStepsDialog } from '../Licenses/ReleaseLicenseStepsDialog';
import { ConfirmationDialog } from '../ConfirmationDialog/ConfirmationDialog';
import { useCustomerUsersApi } from '../../hooks/useCustomerUsersApi';
import { useIntParams } from '../../hooks/useIntParams';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { RowButtonsContainer } from '../RowButtonsContainer/RowButtonsContainer';
import styled from '@mui/styles/styled';
import { useTranslation } from 'react-i18next';
import { getLicenseStatus } from '../../helpers/licenseStatus';
import { useCloseDialog } from '../../hooks/useCloseDialog';
import { AdminRouteLink, useIsEndpointAllowed } from '../RouteLink/RouteLink';
import { CustomerUsersEndpoints } from '../../hooks/api/endpoints/customerUsers';
import { LicenseModeChip } from '../LicenseModeChip/LicenseModeChip';
import { Box, Chip, Divider, Grid } from '@mui/material';
import { RequestOfflineLicenseStandalone } from '../RequestOfflineLicenseStandalone/RequestOfflineLicenseStandalone';
import { newRequestFileExtension } from '../RequestOfflineLicenseStandalone/offlineRequestConst';
import { useDefaultSorting } from '../../hooks/useDefaultSorting';
import { EasyFilter } from '../EasyFilter/EasyFilter';
import { IQuickFiltersProps } from '../EasyFilter/QuickFilters';
import { getBoolFilterOperator } from '../../filterOperators/boolFilterOperator';
import { useHistory } from 'react-router-dom';
import { routeWithQuery } from '../../helpers/routeWithQuery';
import { isNewLicense } from '../Licenses/offlineReleaseConst';
import { ifError } from '../../helpers/ifError';

type IParams = ICustomerUserUrlParams & { licenseId?: string };

const StatusRenderer = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  suspended: ['common.licenseStatus.suspended', 'red'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
  expired: ['common.licenseStatus.expired', 'default'],
});

const NotInUse = styled('span')(({ theme }) => ({
  color: theme.palette.text.disabled,
}));

export const CustomerUserPackages: FC = () => {
  const { data, onRefresh } = useContext(CustomerUserPackagesFetcher.Context);
  const { data: userData } = useContext(CustomerUserFetcher.Context);
  const assignLicenseOpen = useIsRoute(AdminRoutes.CustomerUserAssignLicense);
  const requestOfflineLicenseOpen = useIsRoute(AdminRoutes.CustomerUserRequestNewOffline);
  const isOfflineDialogOpen = useIsRoute(AdminRoutes.CustomerUserOfflineLicense);
  const isReleaseDialogOpen = useIsRoute(AdminRoutes.CustomerUserReleaseLicense);
  const isUnassignDialogOpen = useIsRoute(AdminRoutes.CustomerUserUnassignLicense);
  const {
    forceReleaseOfflineLicense,
    forceReleaseOfflinePackagesMultiple,
    requestOfflineLicense,
    releaseOfflineLicense,
    releaseOfflineLicenseWithAddons,
    releaseOnlineLicense,
    unassignPackage,
    requestOfflineLicenseWithAddons,
    getOfflineRequestVersion,
  } = useCustomerUsersApi();
  const handleError = useApiErrorHandler();
  const { t } = useTranslation();
  const history = useHistory();

  useDefaultSorting('isAddon', 'asc');

  const { customerId, userId, licenseId } = useIntParams<IParams>();

  const path = userData ? AdminRoutes.CustomerUser(userData.customerId, userData.id) : '';
  const closeDialog = useCloseDialog(path, ['force']);

  const onRequestOfflineLicense = useCallback(
    async (file: File, basePackages: number[], addons: number[]) => {
      if (file.name.endsWith(newRequestFileExtension)) {
        await requestOfflineLicenseWithAddons([customerId, userId], file, basePackages, addons);
      } else {
        await requestOfflineLicense([customerId, userId, basePackages[0]], file);
      }
      await onRefresh();

      history.push(
        routeWithQuery(
          AdminRoutes.CustomerUserOfflineLicense,
          [customerId, userId, basePackages[0]],
          {},
          history.location.search
        )
      );
    },
    [customerId, userId, requestOfflineLicenseWithAddons, requestOfflineLicense, onRefresh]
  );

  const chosenPackage = useMemo(() => {
    if (!data) return void 0;
    return data.machinePackages.find((pack) => pack.package.userPackage.id === licenseId);
  }, [data, licenseId]);

  const packagesOnSameLicense = useMemo(() => {
    if (!chosenPackage?.offlineLicense || !data) return [];

    return data.machinePackages
      .filter((machinePackage) => machinePackage.offlineLicense === chosenPackage.offlineLicense)
      .map((machinePackage) => machinePackage.package);
  }, [chosenPackage, data]);

  const onOfflineRelease = useCallback(
    async (file: File) => {
      if (typeof licenseId !== 'number') return;
      try {
        if (file.name.toLowerCase().endsWith('.pxrelease')) {
          await releaseOfflineLicenseWithAddons(
            [customerId, userId],
            file,
            packagesOnSameLicense
              .filter((packageObj) => !packageObj.package.isAddon)
              .map((packageObj) => packageObj.userPackage.id),
            packagesOnSameLicense
              .filter((packageObj) => packageObj.package.isAddon)
              .map((packageObj) => packageObj.userPackage.id)
          );
        } else {
          await releaseOfflineLicense([customerId, userId, licenseId], file);
        }
        await onRefresh();
      } catch (e) {
        handleError(e);
      }
    },
    [
      customerId,
      userId,
      licenseId,
      releaseOfflineLicense,
      releaseOfflineLicenseWithAddons,
      packagesOnSameLicense,
      chosenPackage,
    ]
  );

  const onOfflineForceRelease = useCallback(async () => {
    if (typeof licenseId !== 'number') return;
    try {
      if (isNewLicense(chosenPackage?.offlineLicense ?? '')) {
        await forceReleaseOfflinePackagesMultiple(
          [customerId, userId],
          packagesOnSameLicense
            .filter((packageObj) => !packageObj.package.isAddon)
            .map((packageObj) => packageObj.userPackage.id),
          packagesOnSameLicense
            .filter((packageObj) => packageObj.package.isAddon)
            .map((packageObj) => packageObj.userPackage.id)
        );
      } else {
        await forceReleaseOfflineLicense([customerId, userId, licenseId]);
      }
      closeDialog();
      await onRefresh();
    } catch (e) {
      handleError(e);
    }
  }, [
    customerId,
    userId,
    licenseId,
    forceReleaseOfflineLicense,
    forceReleaseOfflinePackagesMultiple,
    packagesOnSameLicense,
    chosenPackage,
  ]);

  const onOnlineRelease = useCallback(async () => {
    if (typeof licenseId !== 'number') return;
    try {
      await releaseOnlineLicense([customerId, userId, licenseId]);
      closeDialog();
      await onRefresh();
    } catch (e) {
      handleError(e);
    }
  }, [customerId, userId, licenseId, releaseOnlineLicense]);

  const onUnassignLicense = useCallback(async () => {
    if (typeof licenseId !== 'number') return;
    try {
      await unassignPackage([customerId, userId, licenseId]);
      closeDialog();
      await onRefresh();
    } catch (e) {
      handleError(e);
    }
  }, [customerId, userId, licenseId, unassignPackage]);

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'id',
        headerName: t('common.tableHeader.id'),
        width: 80,
      },
      {
        field: 'product',
        headerName: t('customerUser.packages.productHeader'),
        width: 80,
        renderCell: (params) => {
          if (typeof params.value !== 'number') return null;
          return (
            <Link
              component={AdminRouteLink}
              route="CustomerProduct"
              params={[customerId, params.value]}
              underline="hover"
              showFallback
            >
              {params.value}
            </Link>
          );
        },
      },
      {
        field: 'license',
        headerName: t('customerUser.packages.licenseHeader'),
        flex: 1,
        renderCell: (params) => (
          <CellContentTooltip>
            {params.row.isAddon ? <Chip color="info" label="addon" /> : null} {params.value}
          </CellContentTooltip>
        ),
      },
      {
        field: 'expires',
        headerName: t('common.tableHeader.expires'),
        width: 190,
        renderCell: (params) => {
          return <LicenseExpiry type={params.row.type} expiry={params.row.expires} />;
        },
        filterOperators: [getDateBeforeFilterOperator(), getDateAfterFilterOperator()],
      },
      {
        field: 'status',
        headerName: t('common.tableHeader.status'),
        width: 100,
        renderCell: StatusRenderer,
        filterOperators: [
          getEnumIsFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
          getEnumIsNotFilterOperator({
            [t('common.licenseStatus.valid')]: ['valid', 'expiresSoon'],
            [t('common.licenseStatus.expired')]: ['expired'],
            [t('common.licenseStatus.suspended')]: ['suspended'],
          }),
        ],
      },
      {
        field: 'inUseOn',
        headerName: t('common.tableHeader.inUseOn'),
        width: 130,
        renderCell: (params) => {
          if (params.value === t('common.notInUse')) {
            return <NotInUse>{params.value}</NotInUse>;
          }
          if (typeof params.row.machineId === 'number') {
            return (
              <CellContentTooltip>
                <Link
                  component={AdminRouteLink}
                  route="CustomerMachine"
                  params={[customerId, params.row.assigneeId, params.row.machineId]}
                  underline="hover"
                  showFallback
                >
                  {params.value}
                </Link>
              </CellContentTooltip>
            );
          } else {
            return params.value;
          }
        },
      },
      {
        field: 'mode',
        headerName: t('common.tableHeader.mode'),
        width: 120,
        renderCell: LicenseModeChip,
      },
      {
        field: 'buttons',
        headerName: t('common.tableHeader.buttons'),
        renderHeader: () => ' ',
        width: 250,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const { mode } = params.row;
          return (
            <RowButtonsContainer>
              {mode === LicenseMode.OfflineFile ? (
                <Button
                  component={AdminRouteLink}
                  route="CustomerUserOfflineLicense"
                  params={[customerId, userId, params.row.id]}
                  keepQuery
                  variant="outlined"
                  color="primary"
                >
                  {t('customerUser.packages.offlineButton')}
                </Button>
              ) : null}

              {mode === LicenseMode.NotInUse ? null : (
                <Button
                  component={AdminRouteLink}
                  route="CustomerUserReleaseLicense"
                  params={[customerId, userId, params.row.id]}
                  keepQuery
                  variant="outlined"
                  color="primary"
                >
                  {t('customerUser.packages.releaseButton')}
                </Button>
              )}

              {mode === LicenseMode.NotInUse ? (
                <Button
                  component={AdminRouteLink}
                  route="CustomerUserUnassignLicense"
                  params={[customerId, userId, params.row.id]}
                  keepQuery
                  variant="outlined"
                  color="primary"
                >
                  {t('customerUser.packages.unassignButton')}
                </Button>
              ) : null}
            </RowButtonsContainer>
          );
        },
      },
      {
        field: 'isAddon',
        headerName: t('licenses.filter.isAddon'),
        hide: true,
        hideable: false,
        sortable: false,
        filterOperators: [getBoolFilterOperator()],
      },
    ],
    []
  );

  const rows = useMemo<GridRowData[]>(() => {
    if (!data) return [];

    return data.machinePackages.map((item) => {
      const mode = getLicenseMode(item.machine, item.license, item.offlineLicense);
      return {
        id: ifError(() => item.package.userPackage.id, 0),
        product: ifError(() => item.package.userPackage.customerProductId, 0),
        license: ifError(() => `${item.package.package.name} | ${item.plan.name}`, ''),
        type: ifError(() => item.plan.type, undefined),
        expires: ifError(() => item.license.expiry, undefined),
        status: ifError(() => getLicenseStatus(item.license.suspended, item.license.expiry, item.plan.type), undefined),
        inUseOn: ifError(() => (mode === LicenseMode.NotInUse ? t('common.notInUse') : item.machine?.name), undefined),
        machineId: ifError(() => item.machine?.id, undefined),
        assigneeId: ifError(() => item.package.userPackage.userId, undefined),
        mode,
        isAddon: ifError(() => item.package.package.isAddon, undefined),
      };
    });
  }, [data]);

  const isEndpointAllowed = useIsEndpointAllowed();

  const isOfflineForceReleaseAllowed = isEndpointAllowed(
    'DELETE',
    CustomerUsersEndpoints.ForceReleaseOfflinePackage.template
  );

  const anyAddons = rows.some(({ isAddon }) => isAddon);

  const quickFilters = useMemo<IQuickFiltersProps['quickFilters']>(() => {
    return new Map([
      [
        t('licenses.filter.basePackages'),
        {
          filterField: 'isAddon',
          filterOp: 'equals',
          filterVal: 'false',
          isVisible: anyAddons,
        },
      ],
      [
        t('licenses.filter.addonPackages'),
        {
          filterField: 'isAddon',
          filterOp: 'equals',
          filterVal: 'true',
          isVisible: anyAddons,
        },
      ],
    ]);
  }, [anyAddons]);

  const getPixotopeVersion = useCallback(
    (file: File) => getOfflineRequestVersion([customerId, userId], file),
    [getOfflineRequestVersion, customerId, userId]
  );

  if (!data || !userData) return null;
  return (
    <div>
      <Box mt={3} mb={3}>
        <Divider />
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <EasyFilter quickFilters={quickFilters} textFilter={false} />
        </Grid>
        <Grid item xs={8} display="flex" gap={1} pb={3} justifyContent={'right'}>
          <Button
            component={AdminRouteLink}
            route="CustomerUserAssignLicense"
            params={[userData.customerId, userData.id]}
            keepQuery
            variant="contained"
            color="primary"
          >
            {t('customerUser.packages.assignButton')}
          </Button>

          <Button
            component={AdminRouteLink}
            route="CustomerUserRequestNewOffline"
            params={[userData.customerId, userData.id]}
            keepQuery
            variant="outlined"
            color="primary"
          >
            {t('customerUser.packages.requestOfflineButton')}
          </Button>
        </Grid>
      </Grid>

      <UrlQueryDataGrid
        columns={columns}
        rows={rows}
        disableExtendRowFullWidth={false}
        autoHeight={true}
        disableSelectionOnClick={true}
      />

      <CustomerUserAssignLicensesDialog open={assignLicenseOpen} closeDialog={closeDialog} />

      <RequestOfflineLicenseStandalone
        open={requestOfflineLicenseOpen}
        onClose={closeDialog}
        machinePackages={data.machinePackages}
        onSubmit={onRequestOfflineLicense}
        getPixotopeVersion={getPixotopeVersion}
      />

      {chosenPackage ? (
        <LicenseContext.Provider
          value={{
            license: chosenPackage.license,
            offlineLicense: chosenPackage.offlineLicense,
            machine: chosenPackage.machine,
            plan: chosenPackage.package.plan,
            packageDetails: chosenPackage.package.package,
            userPackageDetails: chosenPackage.package.userPackage,
            onOfflineRelease,
            onOnlineRelease,
            onOfflineForceRelease: isOfflineForceReleaseAllowed ? onOfflineForceRelease : void 0,
            getLicenseRoute: AdminRoutes.CustomerUserOfflineLicense,
            releaseLicenseRoute: AdminRoutes.CustomerUserReleaseLicense,
            packagesOnSameLicense,
          }}
        >
          <OfflineAccessStepsDialog open={isOfflineDialogOpen} onClose={closeDialog} />
          <ReleaseLicenseStepsDialog open={isReleaseDialogOpen} onClose={closeDialog} />
        </LicenseContext.Provider>
      ) : null}
      <ConfirmationDialog
        open={isUnassignDialogOpen}
        title={t('customerUser.packages.unassignDialog.title')}
        message={t('customerUser.packages.unassignDialog.message')}
        confirmLabel={t('customerUser.packages.unassignDialog.confirm')}
        confirm={onUnassignLicense}
        abort={closeDialog}
      />
    </div>
  );
};
