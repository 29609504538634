import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  alpha,
  styled,
} from '@mui/material';
import { CloseDialogFunc } from '../../hooks/useCloseDialog';
import { useTranslation } from 'react-i18next';
import { IAdminCustomerUserResponse } from '../../models/AdminCustomerUserResponse';
import { LicenseMode, getLicenseMode } from '../../helpers/licenseMode';
import { LicenseStatus, getLicenseStatus } from '../../helpers/licenseStatus';
import { CellContentTooltip } from '../CellContentTooltip/CellContentTooltip';
import { LicenseExpiry } from '../LicenseExpiry/LicenseExpiry';
import { createStatusChipComponent } from '../StatusChip/StatusChip';
import { CustomerProductPlanType } from '../../models/CustomerProductPlanType';
import { TypographyH5 } from '../Typography/Typography';
import { ifError } from '../../helpers/ifError';

interface IProps {
  onClose: CloseDialogFunc;
  onSubmit: (data: { basePackages: number[]; addonPackages: number[] }) => Promise<void>;
  machinePackages: IAdminCustomerUserResponse['machinePackages'];
  showAddons: boolean;
  basePackagesMultiselect: boolean;
}

const StatusRenderer = createStatusChipComponent({
  valid: ['common.licenseStatus.valid', 'lime'],
  expiresSoon: ['common.licenseStatus.expiresSoon', 'orange'],
});

interface IPackageRow {
  id: number;
  license: string;
  type?: CustomerProductPlanType;
  expires: number;
  status: LicenseStatus;
}

const StyledPackageRow = styled(TableRow)(({ theme, selected }) => ({
  cursor: 'pointer',
  backgroundColor: selected ? theme.palette.primary.main : undefined,
  outline: selected ? `1px solid ${theme.palette.primary.main}` : undefined,
  '&:hover': selected
    ? undefined
    : {
        backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      },
}));

const StyledTable = styled(Table)({
  tableLayout: 'fixed',
});

const StyledIdHeader = styled(TableCell)({
  width: '6.5em',
});

const StyledExpiresHeader = styled(TableCell)({
  width: '15.5em',
});

const BasePackagesTable: FC<{
  rows: Array<IPackageRow>;
  selectedBasePackages: number[];
  onToggleBasePackage: (id: number) => void;
}> = ({ rows, selectedBasePackages, onToggleBasePackage }) => {
  const { t } = useTranslation();

  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <StyledIdHeader>{t('common.tableHeader.id')}</StyledIdHeader>
          <TableCell>{t('customerUser.packages.licenseHeader')}</TableCell>
          <StyledExpiresHeader>{t('common.tableHeader.expires')}</StyledExpiresHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <StyledPackageRow
            key={row.id}
            onClick={() => onToggleBasePackage(row.id)}
            selected={selectedBasePackages.includes(row.id)}
          >
            <TableCell>{row.id}</TableCell>
            <TableCell>
              <CellContentTooltip noWrap={true}>
                {row.license} {row.status === 'expiresSoon' ? <StatusRenderer value={row.status} /> : null}
              </CellContentTooltip>
            </TableCell>
            <TableCell>{row.type ? <LicenseExpiry type={row.type} expiry={row.expires} /> : null}</TableCell>
          </StyledPackageRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};

const AddonPackagesTable: FC<{
  rows: Array<IPackageRow>;
  selectedAddonPackages: number[];
  onToggleAddonPackage: (id: number) => void;
}> = ({ rows, selectedAddonPackages, onToggleAddonPackage }) => {
  return (
    <div>
      {rows.map((row) => (
        <div key={row.id}>
          <label>
            <Checkbox checked={selectedAddonPackages.includes(row.id)} onChange={() => onToggleAddonPackage(row.id)} />
            {row.license} {row.status === 'expiresSoon' ? <StatusRenderer value={row.status} /> : null}(
            {row.type ? <LicenseExpiry type={row.type} expiry={row.expires} /> : 'error'})
          </label>
        </div>
      ))}
    </div>
  );
};

export const RequestOfflineChooseLicense: FC<IProps> = ({
  onClose,
  onSubmit,
  machinePackages,
  showAddons,
  basePackagesMultiselect,
}) => {
  const { t } = useTranslation();
  const [selectedBasePackages, setSelectedBasePackages] = useState<number[]>([]);
  const [selectedAddonPackages, setSelectedAddonPackages] = useState<number[]>([]);

  const onClick = useCallback(() => {
    onSubmit({
      basePackages: selectedBasePackages,
      addonPackages: selectedAddonPackages,
    });
  }, [onSubmit, selectedBasePackages, selectedAddonPackages]);

  const validPackages = useMemo(
    () =>
      machinePackages.filter((item) => {
        const mode = getLicenseMode(item.machine, item.license, item.offlineLicense);
        const status = getLicenseStatus(item.license.suspended, item.license.expiry, item.plan.type);
        return mode === LicenseMode.NotInUse && ['expiresSoon', 'valid'].includes(status);
      }),
    [machinePackages]
  );

  const basePackages = useMemo(() => validPackages.filter((item) => !item.package.package.isAddon), [validPackages]);

  const addonPackages = useMemo(() => validPackages.filter((item) => item.package.package.isAddon), [validPackages]);

  const baseRows = useMemo<IPackageRow[]>(() => {
    return basePackages.map((item) => {
      return {
        id: ifError(() => item.package.userPackage.id, 0),
        license: ifError(() => `${item.package.package.name} | ${item.plan.name}`, ''),
        type: ifError(() => item.plan.type, undefined),
        expires: ifError(() => item.license.expiry, 0),
        status: ifError(() => getLicenseStatus(item.license.suspended, item.license.expiry, item.plan.type), 'expired'),
      };
    });
  }, [basePackages]);

  const addonRows = useMemo<IPackageRow[]>(() => {
    return addonPackages.map((item) => {
      return {
        id: ifError(() => item.package.userPackage.id, 0),
        license: ifError(() => `${item.package.package.name} | ${item.plan.name}`, ''),
        type: ifError(() => item.plan.type, undefined),
        expires: ifError(() => item.license.expiry, 0),
        status: ifError(() => getLicenseStatus(item.license.suspended, item.license.expiry, item.plan.type), 'expired'),
      };
    });
  }, [addonPackages]);

  const toggleAddonPackage = useCallback(
    (id: number) => {
      if (selectedAddonPackages.includes(id)) {
        setSelectedAddonPackages(selectedAddonPackages.filter((packageId) => packageId !== id));
      } else {
        setSelectedAddonPackages([...selectedAddonPackages, id]);
      }
    },
    [selectedAddonPackages, addonPackages]
  );

  const toggleBasePackage = useCallback(
    (id: number) => {
      if (basePackagesMultiselect) {
        if (selectedBasePackages.includes(id)) {
          setSelectedBasePackages(selectedBasePackages.filter((packageId) => packageId !== id));
        } else {
          setSelectedBasePackages([...selectedBasePackages, id]);
        }
      } else {
        setSelectedBasePackages([id]);
      }
    },
    [selectedBasePackages, basePackages, basePackagesMultiselect]
  );

  return (
    <>
      <DialogTitle>{t('licenses.offlineDialog.title')}</DialogTitle>
      <DialogContent>
        {baseRows.length === 0 ? (
          t('component.requestOffline.noAvailableLicenses')
        ) : (
          <>
            <Box p={1}>
              <TypographyH5>{t('component.requestOffline.selectPackage')}</TypographyH5>
            </Box>
            <BasePackagesTable
              rows={baseRows}
              selectedBasePackages={selectedBasePackages}
              onToggleBasePackage={toggleBasePackage}
            />
          </>
        )}

        {/* Don't show addons if there are no base licenses available */}
        {showAddons && baseRows.length > 0 && addonRows.length > 0 ? (
          <>
            <Box p={1}>
              <TypographyH5>{t('component.requestOffline.chooseAddons')}</TypographyH5>
            </Box>
            <AddonPackagesTable
              rows={addonRows}
              selectedAddonPackages={selectedAddonPackages}
              onToggleAddonPackage={toggleAddonPackage}
            />
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          {t('common.dialog.close')}
        </Button>
        <Button variant="contained" color="primary" disabled={selectedBasePackages.length === 0} onClick={onClick}>
          {t('component.requestOffline.confirm')}
        </Button>
      </DialogActions>
    </>
  );
};
