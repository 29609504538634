import React, { FC, useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { RequestOfflineLicense } from '../Licenses/RequestOfflineLicense';
import { RequestOfflineChooseLicense } from './RequestOfflineChooseLicense';
import { IAdminCustomerUserResponse } from '../../models/AdminCustomerUserResponse';
import { useApiErrorHandler } from '../../hooks/useApiErrorHandler';
import { newRequestFileExtension } from './offlineRequestConst';
import { IAirgapRequestVersionResponse } from '../../types/AirgapRequestVersion';

interface IProps {
  open: boolean;
  onClose: () => void;
  machinePackages: IAdminCustomerUserResponse['machinePackages'];
  onSubmit: (file: File, basePackages: number[], addonPackages: number[]) => Promise<void>;
  getPixotopeVersion: (file: File) => Promise<IAirgapRequestVersionResponse>;
}

export const RequestOfflineLicenseStandalone: FC<IProps> = ({
  open,
  onClose,
  machinePackages,
  onSubmit,
  getPixotopeVersion,
}) => {
  const handleApiError = useApiErrorHandler();
  const [requestFile, setRequestFile] = useState<File | null>(null);
  const [canSelectMultipleBasePackages, setCanSelectMultipleBasePackages] = useState<null | boolean>(null);

  const canUseAddons = requestFile ? requestFile.name.endsWith(newRequestFileExtension) : false;

  const checkPixotopeVersion = useCallback(
    async (file: File) => {
      try {
        const { appVersion } = await getPixotopeVersion(file);
        const [year, major] = appVersion.split('.').map((val) => parseInt(val));
        setCanSelectMultipleBasePackages(isNaN(year) || year > 24 || (year === 24 && major >= 3));
      } catch (e) {
        handleApiError(e);
      }
    },
    [getPixotopeVersion, handleApiError]
  );

  useEffect(() => {
    if (requestFile) {
      if (requestFile.name.endsWith(newRequestFileExtension)) {
        checkPixotopeVersion(requestFile).then();
      } else {
        setCanSelectMultipleBasePackages(false);
      }
    }
  }, [requestFile, checkPixotopeVersion]);

  const onSetFile = useCallback(async (file: File) => {
    setCanSelectMultipleBasePackages(null);
    setRequestFile(file);
  }, []);

  const onChooseLicense = useCallback(
    async (data: { basePackages: number[]; addonPackages: number[] }) => {
      try {
        await onSubmit(requestFile!, data.basePackages, canUseAddons ? data.addonPackages : []);
      } catch (e) {
        handleApiError(e);
      }
    },
    [onSubmit, requestFile, handleApiError]
  );

  useEffect(() => {
    setRequestFile(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'md'}>
      {requestFile && typeof canSelectMultipleBasePackages === 'boolean' ? (
        <RequestOfflineChooseLicense
          onSubmit={onChooseLicense}
          onClose={onClose}
          machinePackages={machinePackages}
          showAddons={canUseAddons}
          basePackagesMultiselect={canSelectMultipleBasePackages}
        />
      ) : (
        <RequestOfflineLicense onClose={onClose} onOfflineRequest={onSetFile} />
      )}
    </Dialog>
  );
};
