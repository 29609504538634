import { useApi } from './useApi';
import { ICustomerUsersResponse } from '../models/CustomerUsersResponse';
import { ICustomerUserResponse } from '../models/CustomerUserResponse';
import { IUserDetails } from '../models/UserDetails';
import { IPaginatedContent } from '../models/PaginatedContent';
import { UsersEndpoints } from './api/endpoints/users';
import { ApiRequest, useEndpoint, usePaginatedGetEndpoint, useSimpleEndpoint } from './api/apiEndpoint';
import { IShortCustomerData } from '../models/AccountResponse';
import { IAirgapRequestVersionResponse } from '../types/AirgapRequestVersion';

export interface IAssignLicensePayload {
  packages: {
    amount: number;
    customerProductId: number;
    packageId: number;
  };
}

export type IUpdateUserPayload = Pick<IUserDetails, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>;

export const useUsersApi = () => {
  const { del, get, post, put } = useApi();

  const getCustomerUsers = useEndpoint(
    (get, query?: Partial<{ product: number }>) => get({ params: query }),
    UsersEndpoints.Users,
    get as ApiRequest<typeof get, ICustomerUsersResponse>
  );

  const getAllUsers = usePaginatedGetEndpoint(
    UsersEndpoints.Users,
    get as ApiRequest<
      typeof get,
      IPaginatedContent<{ customer: IShortCustomerData & { archived: boolean }; user: IUserDetails }>
    >
  );

  const getCustomerUser = useSimpleEndpoint(UsersEndpoints.User, get as ApiRequest<typeof get, ICustomerUserResponse>);

  const reInviteUser = useEndpoint(
    // yes, request payload is redundant here but api expects it
    (post, id: number) => post({ id }),
    UsersEndpoints.ReInvite,
    post as ApiRequest<typeof post, void>
  );

  const unassignLicense = useSimpleEndpoint(UsersEndpoints.UnassignLicense, del);

  const assignLicense = useEndpoint(
    (post, payload: IAssignLicensePayload) => post(payload),
    UsersEndpoints.AssignLicense,
    post as ApiRequest<typeof post, void>
  );

  const offlineActivate = useEndpoint(
    (post, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return post(formData);
    },
    UsersEndpoints.OfflineLicense,
    post as ApiRequest<typeof post, void>
  );

  const requestOfflineLicenseWithAddons = useEndpoint(
    (post, file: File, basePackages: number[], addonPackages: number[]) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              basePackages,
              addonPackages,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );
      return post(formData);
    },
    UsersEndpoints.PackagesOffline,
    post as ApiRequest<typeof post, void>
  );

  const getOfflineRequestVersion = useEndpoint(
    (post, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return post(formData);
    },
    UsersEndpoints.OfflineRequestVersion,
    post as ApiRequest<typeof post, IAirgapRequestVersionResponse>
  );

  const offlineRelease = useEndpoint(
    (del, file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      return del({ data: formData });
    },
    UsersEndpoints.OfflineLicense,
    del as ApiRequest<typeof del, void>
  );

  const offlineReleaseWithAddons = useEndpoint(
    (del, file: File, basePackages: number[], addonPackages: number[]) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              basePackages,
              addonPackages,
            }),
          ],
          {
            type: 'application/json',
          }
        )
      );
      return del({ data: formData });
    },
    UsersEndpoints.PackagesOffline,
    del as ApiRequest<typeof del, void>
  );

  const onlineRelease = useSimpleEndpoint(UsersEndpoints.OnlineLicense, del);

  const updateUser = useEndpoint(
    (put, payload: Partial<IUpdateUserPayload>) => put(payload),
    UsersEndpoints.User,
    put as ApiRequest<typeof put, IUserDetails>
  );

  const deleteUser = useSimpleEndpoint(UsersEndpoints.User, del);

  const createUser = useEndpoint(
    (post, customerId: number, payload: IUpdateUserPayload) => post({ ...payload, customerId }),
    UsersEndpoints.Users,
    post as ApiRequest<typeof post, { email: string; id: number }>
  );

  return {
    getCustomerUsers,
    getCustomerUser,
    reInviteUser,
    unassignLicense,
    assignLicense,
    offlineActivate,
    requestOfflineLicenseWithAddons,
    getOfflineRequestVersion,
    offlineRelease,
    offlineReleaseWithAddons,
    onlineRelease,
    updateUser,
    deleteUser,
    createUser,
    getAllUsers,
  } as const;
};
